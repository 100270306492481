<div id="home">
  <section id="banner" *ngIf="currentBanner == 1" class="d-flex justify-content-center align-items-center black">
    <div class="container d-flex justify-content-center align-items-center">
      <img class="img active" src="./assets/pretoTAG.png">
    </div>
  </section>
  <section id="banner" *ngIf="currentBanner == 2" class="d-flex justify-content-center align-items-center yellow">
    <div class="container d-flex justify-content-center align-items-center">
      <img class="img active" src="./assets/amarelo-tag-banner.png">
    </div>
  </section>
  <section id="banner" *ngIf="currentBanner == 3" class="d-flex justify-content-center align-items-center white">
    <div class="container d-flex justify-content-center align-items-center">
      <img class="img active" src="./assets/branco-tag-banner.png">
    </div>
  </section>
  <section id="quem-somos">
    <div class="container d-flex flex-column justify-content-center align-items-center ">
      <div class="text d-flex flex-column justify-content-center align-items-center">
        <h1 class="reveal">QUEM SOMOS</h1>
        <p class="reveal my-5">Criamos conexões entre marcas e pessoas, durante todos os momentos da jornada. Unimos
          mídia,
          dados e muita criatividade de maneira inovadora.<br> Criamos experiencias que vão além da relação de compra
          e venda. Fidelizamos o shopper.</p>
      </div>
      <div class="image d-flex justify-content-center align-items-center reveal">
        <img src="./assets/publicis-groupe.svg" alt="" class="img-fluid px-2">
      </div>
    </div>
  </section>
  <section id="filosofia">
    <div class="container d-flex flex-column align-items-center">
      <h1 class="title text-center reveal">NOSSA FILOSOFIA</h1>
      <!-- <div class="main-text-container reveal">
        <h1><span>#</span>Jornadas<span>Que</span>Apaixonam</h1>
        <img src="./assets/stamp-fidelizar.png" alt="" class="stamp">
      </div> -->
      <img src="./assets/jornadas.png" alt="" class="journey reveal img-fluid mb-2">
      <p class="text-center reveal">Uma filosofia ARC para criar relações intensas <br> e duradoras entre marcas e
        shoppers.
      </p>
      <img class="img-fluid reveal" src="./assets/home.gif" alt="">
      <p class="text-center reveal">A jornada de compra não é uma linha reta. Ela é cheia de desafios e oportunidades.
        Assim como
        toda relação. E
        qual nosso papel nesse caminho? Tornar a jornada de compra um ciclo que se repita diversas vezes, gerando cada
        vez
        mais compras. Contando sempre com a nossa exclusiva análise de mercado, criatividade e estratégias que fogem do
        comum. Tudo com um objetivo: <b>fidelizar clientes</b>.
      </p>

      <img class="mt-5 reveal" src="./assets/arrows-down.png" alt="">
    </div>
  </section>
  <section id="projetos">
    <div class="container d-flex justify-content-center align-items-end">
      <div class="title-container reveal">
        <div class="title">
          <h1>Projetos</h1>
          <img src="./assets/stamp-apaixonados.png" alt="" class="stamp">
        </div>
      </div>
      <div class="project-1 reveal" [routerLink]="['/inconfundivel-hotel']">
        <img class="img-project" src="./assets/hotel.png" alt="">
      </div>
      <div class="project-2 reveal" [routerLink]="['/casa-zoster']">
        <img class="img-project" src="./assets/gsk.png" alt="">
      </div>
      <div class="project-3 reveal" [routerLink]="['/baileys-ovo']">
        <img class="img-project" src="./assets/projeto-3.png" alt="">
      </div>
      <div class="project-4 reveal" [routerLink]="['/inconfundivel-talks']">
        <img class="img-project" src="./assets/Inconf_Talks.png" alt="">
      </div>
      <h1 class="text-center pointer see-more" [routerLink]="['/projetos']">+VER MAIS PROJETOS</h1>
    </div>
  </section>
  <section id="oque-fazemos">
    <div class="container">
      <h1 class="text-center reveal">O QUE FAZEMOS</h1>
      <p class="text-center text reveal mt-5">Entregamos as melhores experiencias em creative commerce</p>
      <div class="d-flex flex-column flex-lg-row align-items-baseline justify-content-between">
        <div class="reveal">
          <div class="item d-flex flex-column align-items-start justify-content-center px-2 mt-5">
            <p class="title">
              Promo <span>&</span> Ativação
            </p>
            <p class="text">
              Desenvolvemos estratégias de promoções e ativações que despertam o shopper para a compra, geram vendas e
              fortalecem marcas.
            </p>
          </div>
        </div>
        <div class="reveal">
          <div class="item d-flex flex-column align-items-start justify-content-center px-2">
            <p class="title">
              Experiência de Marca
            </p>
            <p class="text">
              Criamos experiências que materializam a marca criando relacionamentos profundos e contínuos com o shopper.
            </p>
          </div>
        </div>
        <div class="reveal">
          <div class="item d-flex flex-column align-items-start justify-content-center px-2">
            <p class="title">
              Trade <span>&</span><br> e-commerce
            </p>
            <p class="text">
              Geramos experiências de compra atraentes em cada etapa da jornada. Estratégias impactantes que são
              traduzidas
              em conteúdo virtual, retail design e materiais de ponto de venda.
            </p>
          </div>
        </div>
        <div class="reveal">
          <div class="item d-flex flex-column align-items-start justify-content-center px-2">
            <p class="title">
              Conteúdo de Jornada
            </p>
            <p class="text">
              Desenvolvemos conteúdos para aproximar marcas e shoppers visando construção de marca para impulsionar
              vendas
              e
              resultados.
            </p>
          </div>
        </div>
        <div class="reveal">
          <div class="item d-flex flex-column align-items-start justify-content-center px-2">
            <p class="title">
              Parcerias Estratégicas
            </p>
            <p class="text">
              Conectamos marcas de diversos setores para estimular vendas, aproximar shopper e clientes e ainda gerar
              novos
              modelos de negócios.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="board">
    <div class="container">
      <div class="row w-100 m-0" *ngIf="!isPersonInfoOpen">
        <div id="anchor"></div>
        <div class="person pointer col-12 col-lg-4 d-flex flex-column align-items-center justify-content-center"
          (click)="showPersonInfo('marilia')">
          <img src="./assets/marilia2.jpg" alt="">
          <span class="name">Marília <br>Queiroz</span>
          <p>MANAGING DIRECTOR</p>
        </div>
        <div class="person pointer col-12 col-lg-4 d-flex flex-column align-items-center justify-content-center"
          (click)="showPersonInfo('guilherme')">
          <img src="./assets/guilherme2.jpg" alt="">
          <span class="name">Guilherme <br>Dias</span>
          <p>STRATEGY DIRECTOR</p>
        </div>
        <div class="person pointer col-12 col-lg-4 d-flex flex-column align-items-center justify-content-center"
          (click)="showPersonInfo('tati')">
          <img src="./assets/tati2.jpg" alt="">
          <span class="name">Tati <br>Piovezam</span>
          <p>CREATIVE DIRECTOR</p>
        </div>
      </div>
      <div class="row w-100 d-flex align-items-center justify-content-center" *ngIf="isPersonInfoOpen">
        <div class="personInfo col-9 d-flex flex-column flex-lg-row align-items-center justify-content-center">
          <div class="d-flex flex-column align-items-center justify-content-center">
            <div class="align-self-end pointer d-block d-lg-none" (click)="hidePersonInfo()">
              <i class="close mb-4 fa-thin fa-x fa-2xl"></i>
            </div>
            <img [src]="imagePerson" alt="" class="align-self-start img-fluid">
            <span class="name">{{ namePerson }}</span>
            <p>{{ rolePerson }}</p>
          </div>
          <div class="content d-flex flex-column">
            <div class="align-self-end pointer d-none d-lg-block" (click)="hidePersonInfo()">
              <i class="fa-thin fa-x fa-2xl"></i>
            </div>

            <p><span class="name-person">{{ nameAbrvPerson }}</span> {{ textPerson }}</p>
          </div>
        </div>
      </div>
      <a class="stampBoard" id="stampBoard" href="/#board">
        <img src="./assets/conheca-board.png" alt="">
      </a>
    </div>
  </section>
</div>
<div id="contato"></div>
