import { ViewportScroller } from '@angular/common';
import {
  Component,
  HostBinding,
  OnInit,
  ElementRef,
  HostListener,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { interval } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  @HostBinding('class.image-transition') componentClass = true;
  @ViewChild('personInfo') personInfo: ElementRef | undefined;

  isActive = true;
  animationInProgress = false;
  currentImage = 0;
  currentBanner: number = 1;
  images = [
    './assets/pretoTAG.png',
    './assets/amarelo-tag-banner.png',
    './assets/branco-tag-banner.png',
  ];
  rootNode: any;

  isPersonInfoOpen: boolean = false;
  namePerson: any;
  rolePerson: any;
  textPerson: any;
  imagePerson: any;
  nameAbrvPerson: any;

  constructor(
    private elementRef: ElementRef,
    private scroller: ViewportScroller,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.rootNode = this.elementRef.nativeElement;
    this.animationInProgress = false;

    this.changeBanner();

    setInterval(() => {
      this.isActive = false;
      this.changeBannerImage().then(() => {
        this.isActive = true;
      });
    }, 5000);
  }

  changeBanner() {
    interval(5000) // cria um intervalo de 5 segundos
      .subscribe(() => {
        // inscreve-se no intervalo
        if (this.currentBanner === 1) {
          this.currentBanner = 2;
        } else if (this.currentBanner === 2) {
          this.currentBanner = 3;
        } else {
          this.currentBanner = 1;
        }
      });
  }

  changeBannerImage() {
    setTimeout(() => {
      this.currentImage = (this.currentImage + 1) % this.images.length;
      if (this.currentImage === 0) {
        this.animationInProgress = true;
      }
    }, 5000);
    return Promise.resolve();
  }

  //Função para revelar elementos na tela
  reveal() {
    var reveals = this.rootNode.querySelectorAll('.reveal');
    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      //elementVisible define em qual height vai revelar o elemento
      var elementVisible = 50;
      if (elementTop < windowHeight! - elementVisible!) {
        reveals[i].classList.add('active');
      } else {
        reveals[i].classList.remove('active');
      }
    }
  }

  //Event Listener do scroll da tela
  @HostListener('window:scroll', []) onWindowScroll() {
    this.reveal();
    const scrollPosition =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    if (scrollPosition > 450) {
      document.getElementById('stampBoard')!.classList.add('active');
    } else {
      document.getElementById('stampBoard')!.classList.remove('active');
    }
  }

  showPersonInfo(person: any) {
    switch (person) {
      case 'marilia':
        this.isPersonInfoOpen = true;
        this.namePerson = 'Marília Queiroz';
        this.nameAbrvPerson = 'Marília,';
        this.rolePerson = 'MANAGING DIRECTOR';
        this.textPerson =
          ' mãe do Be e do Leco, passou por grandes agencias de eventos e shopper, acumula experiências como gestora de marketing em diversos segmentos do varejo, tendo atuado em empresas como Claro, GrandVision, Iguatemi e Anima Educação. Liderou grandes projetos de reposicionamento de marcas e já conquistou alguns prêmios importantes de comunicação.';
        this.imagePerson = './assets/marilia2.jpg';
        this.router.navigate([], { fragment: 'board' });
        break;
      case 'tati':
        this.isPersonInfoOpen = true;
        this.namePerson = 'Tati Piovezam';
        this.nameAbrvPerson = 'Tati Piovezam';
        this.rolePerson = 'CREATIVE DIRECTOR';
        this.textPerson =
          ' ou para os informais, TatiPi, mãe de Helena e Joana – começou a publicitar com 17 anos de idade. Sua jornada é longa e passou por quase todas as disciplinas de comunicação dessa vida. Publicidade raiz, live marketing, retail marketing, os BTL’s de todos os tipos e formatos, mas se agarra mesmo é em construir comunicação que pegue o shopper pelo coração.';
        this.imagePerson = './assets/tati2.jpg';
        this.router.navigate([], { fragment: 'board' });
        break;
      case 'guilherme':
        this.isPersonInfoOpen = true;
        this.namePerson = 'Guilherme Dias';
        this.nameAbrvPerson = 'Guilherme';
        this.rolePerson = 'STRATEGY DIRECTOR';
        this.textPerson =
          ' já atuou em grandes agências de publicidade e mais de 5 anos em consultorias de Branding. Está há 11 anos no Publicis Groupe, desenvolvendo estratégias de comunicação e shopper marketing para grandes clientes do grupo. Foi um dos profissionais que ajudou a trazer a Arc para o Brasil.';
        this.imagePerson = './assets/guilherme2.jpg';
        this.router.navigate([], { fragment: 'board' });
        break;
    }
  }

  hidePersonInfo() {
    this.isPersonInfoOpen = false;
    this.router.navigate([], { fragment: 'anchor' });
  }
}
