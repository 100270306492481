import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-back-projects',
  templateUrl: './back-projects.component.html',
  styleUrls: ['./back-projects.component.scss'],
})
export class BackProjectsComponent implements OnInit {
  constructor(private location: Location) {}

  ngOnInit(): void {}

  goBack() {
    this.location.back();
  }
}
