<section id="project-detail">
  <div class="container">
    <div class="row banner">
      <div class="col-12">
        <!-- <img src="./assets/projetos/baileys/ovo/banner.png" alt="" class="img-fluid"> -->
        <div class="video-container">
          <iframe src="https://www.youtube.com/embed/Uq070tWwwfs" frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope;" allowfullscreen></iframe>
        </div>
      </div>
      <div class="col-12 col-lg-6 mb-2 my-lg-5 pb-lg-5">
        <h1 class="brand">Baileys</h1>
        <h1 class="project-title">Ovo Proibido Para Menores</h1>
      </div>
      <div class="col-12 col-lg-6 mb-5 my-lg-5 pb-lg-5">
        <p class="project-description"><strong>Baileys e Carole Crema lançam o primeiro ovo de Páscoa proibido para
            menores.</strong></p>

        <p class="project-description">A iniciativa inédita criada pela agência em parceria com a Pâtissier Carole Crema
          criou uma receita própria junto com Baileys para lançar o novo produto. O ovo foi inspirado em um
          comportamento analisado no público adulto, que já não sente mais a magia das surpresas da Páscoa. A estratégia
          criativa foi unir este comportamento ao território de marca, a indulgência, e trazer o protagonismo da marca
          na ocasião de consumo. Por isso, o ovo com recheio de Baileys foi criado para resgatar o sentimento de alegria
          e a surpresa na data mais doce do ano de quem já cresceu. Uma receita deliciosa com uma dose de ousadia.
          Afinal, Baileys só pode ser consumido por maiores de idade, pois combina Whisky e leite fresco irlandês na sua
          receita. </p>

      </div>
    </div>
    <div class="row">
      <div class="col-12 content">
        <div class="element-1 reveal">
          <img class="img-fluid" src="./assets/projetos/baileys/ovo/image1.png" alt="">
        </div>
        <div class="element-2 reveal">
          <img class="img-fluid" src="./assets/projetos/baileys/ovo/image2.png" alt="">
        </div>
        <div class="element-3 reveal">
          <img class="img-fluid" src="./assets/projetos/baileys/ovo/image3.png" alt="">
        </div>
        <div class="element-4 reveal">
          <img class="img-fluid" src="./assets/projetos/baileys/ovo/image4.png" alt="">
        </div>
        <div class="element-5 reveal">
          <img class="img-fluid" src="./assets/projetos/baileys/ovo/image5.png" alt="">
        </div>
        <div class="element-6 reveal d-flex premio">
          <div class="text d-flex flex-column align-items-start justify-content-center">
            <h1 class="title">Prêmio<br>POPAI'22</h1>
            <p class="subtitle">Prata</p>
            <p>Ações de Trade Marketing</p>
          </div>
          <img src="./assets/projetos/baileys/ovo/premio.png" alt="">
        </div>
      </div>
      <div class="col-12 my-5 d-flex alignitems-center justify-content-center mb-5">
        <p class="close pointer" (click)="goBack()"><i class="fa-thin fa-xmark fa-xl me-2"></i>FECHAR</p>
      </div>
    </div>
    <app-back-projects></app-back-projects>
  </div>
</section>
