<section id="projects">
  <div class="container p-0">
    <div class="title-container">
      <div class="title">
        <h1>Projetos</h1>
        <img src="./assets/stamp-apaixonados.png" alt="" class="stamp">
      </div>
    </div>
    <div class="project row mb-5" [routerLink]="['/inconfundivel-hotel']">
      <!-- Hotel inconfundivel -->
      <div class="col-12">
        <img src="./assets/hotel.png" alt="" class="img-fluid">
      </div>
      <div class="col-12 mt-4">
        <p class="brand ms-2">
          Tanqueray
        </p>
        <p class="title ms-2">
          Hotel Inconfundível Tanqueray
        </p>
      </div>
    </div>
    <div class="project  row d-flex flex-column-reverse flex-lg-row mb-5 reveal" [routerLink]="['/casa-zoster']">
      <!-- GSK -->
      <div class="col-12 col-lg-4 d-flex flex-column justify-content-center align-items-start">
        <p class="brand ms-2">
          GSK
        </p>
        <p class="title ms-2">
          Casa Zoster
        </p>
      </div>
      <div class="col-12 col-lg-8  d-flex flex-column justify-content-center align-items-center">
        <img src="./assets/gsk.png" alt="" class="img-fluid">
      </div>
    </div>
    <div class="project row mb-5 reveal" [routerLink]="['/baileys-ovo']">
      <!-- Baileys Ovo -->
      <div class="col-12">
        <img src="./assets/baileys-ovo.png" alt="" class="img-fluid">
      </div>
      <div class="col-12 mt-4">
        <p class="brand ms-2">
          Baileys
        </p>
        <p class="title ms-2">
          Ovo Proibido para Menores
        </p>
      </div>
    </div>
    <div class="project row d-flex flex-column flex-lg-row mb-5 reveal" [routerLink]="['/inconfundivel-talks']">
      <!-- Inconfundível Talks -->
      <div class="col-12 col-lg-6  d-flex flex-column justify-content-center align-items-start">
        <img src="./assets/Inconf_Talks.png" alt="" class="img-fluid">
      </div>
      <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-start">
        <p class="brand ms-2">
          Tanqueray
        </p>
        <p class="title ms-2">
          Inconfundível Talks
        </p>
      </div>
    </div>
    <div class="project  row d-flex flex-column-reverse flex-lg-row mb-5 reveal"
      [routerLink]="['/baileys-presenteria']">
      <!-- Baileys Presenteria -->
      <div class="col-12 col-lg-5 d-flex flex-column justify-content-center align-items-start mt-4">
        <p class="brand ms-2">Baileys</p>
        <p class="title ms-2">Presenteria</p>
      </div>
      <div class="col-12 col-lg-7  d-flex flex-column justify-content-center align-items-center">
        <img src="./assets/PresenteriaBaileys.gif" alt="" class="img-fluid">
      </div>
    </div>
    <div class="project  row d-flex flex-column flex-lg-row mb-5 reveal" [routerLink]="['/gordons-stewards']">
      <!-- Gin Gordon's - Steward de Gelo -->
      <div class="col-12 col-lg-6  d-flex flex-column justify-content-center align-items-start">
        <img src="./assets/steward.png" alt="" class="img-fluid">
      </div>
      <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-start">
        <p class="brand ms-2">
          Gin Gordon's
        </p>
        <p class="title ms-2">
          Steward de Gelo
        </p>
      </div>
    </div>
    <div class="project  row d-flex flex-column-reverse flex-lg-row mb-5 reveal" [routerLink]="['/baileys-loja']">
      <!-- Baileys Loja -->
      <div class="col-12 col-lg-5 d-flex flex-column justify-content-center align-items-start">
        <p class="brand ms-2">Baileys</p>
        <p class="title ms-2">1º loja exclusiva de Baileys no BR</p>
      </div>
      <div class="col-12 col-lg-7  d-flex flex-column justify-content-center align-items-center">
        <img src="./assets/BAILEYS_TREATBAR.jpg" alt="" class="img-fluid">
      </div>
    </div>
    <div class="project row mb-5 reveal" [routerLink]="['/tanqueray-lolla']">
      <!-- Tanqueray LollaBR -->
      <div class="col-12">
        <img src="./assets/lolla.png" alt="" class="img-fluid">
      </div>
      <div class="col-12 mt-4">
        <p class="brand ms-2">
          Tanqueray
        </p>
        <p class="title ms-2">
          LollaBR'22
        </p>
      </div>
    </div>
    <div class="project row d-flex flex-column flex-lg-row mb-5 reveal" [routerLink]="['/gordons-praia']">
      <!-- Gin Gordon's - Praia de Paulistano -->
      <div class="col-12 col-lg-6  d-flex flex-column justify-content-center align-items-start">
        <img src="./assets/praia.png" alt="" class="img-fluid">
      </div>
      <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-start">
        <p class="brand ms-2">
          Gin Gordon's
        </p>
        <p class="title ms-2">
          Praia de Paulistano
        </p>
      </div>
    </div>
    <div class="project  row d-flex flex-column-reverse flex-lg-row mb-5 reveal" [routerLink]="['/renova-br']">
      <!-- Renova BR -->
      <div class="col-12 col-lg-5 d-flex flex-column justify-content-center align-items-start mt-4">
        <p class="brand ms-2">Renova BR</p>
        <p class="title ms-2">A Rampa do Conhecimento</p>
      </div>
      <div class="col-12 col-lg-7  d-flex flex-column justify-content-center align-items-center">
        <img src="./assets/projetos/renova/image7.png" alt="" class="img-fluid">
      </div>
    </div>
    <div class="project row d-flex flex-column flex-lg-row mb-5 reveal" [routerLink]="['/motorola-presenteria']">
      <!-- Motorola Presenteria -->
      <div class="col-12 col-lg-6  d-flex flex-column justify-content-center align-items-start">
        <img src="./assets/Motorola_Maes.jpg" alt="" class="img-fluid">
      </div>
      <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-start">
        <p class="brand ms-2">
          Motorola
        </p>
        <p class="title ms-2">
          Presenteria Dia das Mães
        </p>
      </div>
    </div>
  </div>
</section>
