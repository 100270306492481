<section id="project-detail">
  <div class="container">
    <div class="row banner">
      <div class="col-12">
        <img src="./assets/projetos/gordons/praia/banner.png" alt="" class="img-fluid">
      </div>
      <div class="col-12 col-lg-6 mb-2 my-lg-5 pb-lg-5">
        <h1 class="brand">Gordon’s</h1>
        <h1 class="project-title">Praia de Paulistano Gordon’s</h1>
      </div>
      <div class="col-12 col-lg-6 mb-5 my-lg-5 pb-lg-5">
        <p class="project-description">O calor que tem feito na cidade de São Paulo faz as pessoas desejarem ainda mais
          aquela brisa gostosa do mar. E, para mostrar que só Gordon’s é capaz de dar um gelo na rotina, criamos a ação
          “Praia de Paulistano”, que leva o clima de praia para dois pontos icônicos de São Paulo.
          O projeto é inspirado no comportamento das pessoas que vivem na cidade e adotam parques e avenidas como suas
          praias. </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 content">
        <div class="element-1 reveal">
          <img class="img-fluid" src="./assets/projetos/gordons/praia/gif1.gif" alt="">
        </div>
        <div class="element-2 reveal">
          <img class="img-fluid" src="./assets/projetos/gordons/praia/image2.png" alt="">
        </div>
        <div class="element-3 reveal">
          <img class="img-fluid" src="./assets/projetos/gordons/praia/gif3.gif" alt="">
        </div>
        <div class="element-4 reveal">
          <img class="img-fluid" src="./assets/projetos/gordons/praia/image4.png" alt="">
        </div>
        <div class="element-5 reveal">
          <img class="img-fluid" src="./assets/projetos/gordons/praia/gif5.gif" alt="">
        </div>
        <div class="element-6 reveal">
          <!-- <img class="img-fluid" src="./assets/projetos/gordons/praia/image6.png" alt=""> -->
          <div class="video-container-vertical">
            <iframe src="https://www.youtube.com/embed/QqUAA5rQLqY" frameborder="0" allowfullscreen></iframe>
          </div>
        </div>
        <div class="element-7 reveal">
          <img class="img-fluid" src="./assets/projetos/gordons/praia/image7.png" alt="">
        </div>
      </div>
      <div class="col-12 my-5 d-flex alignitems-center justify-content-center mb-5">
        <p class="close pointer" (click)="goBack()"><i class="fa-thin fa-xmark fa-xl me-2"></i>FECHAR</p>
      </div>
    </div>
    <app-back-projects></app-back-projects>
  </div>
</section>
