import { AppModule } from './app.module';
import { HeaderComponent } from './shared/header/header.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './shared/footer/footer.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { BackProjectsComponent } from './shared/back-projects/back-projects.component';
import { AppRoutingModule } from './app-routing.module';
import { StylesheetComponent } from './shared/stylesheet/stylesheet.component';

/* var modules = {
  HeaderComponent
}; */

@NgModule({
  declarations: [HeaderComponent, FooterComponent, BackProjectsComponent, StylesheetComponent],
  imports: [CommonModule, SlickCarouselModule, AppRoutingModule],
  exports: [HeaderComponent, FooterComponent, BackProjectsComponent],
})
export class AppSharedModule {}
