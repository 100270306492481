<section id="project-detail">
  <div class="container">
    <div class="row banner">
      <div class="col-12">
        <img src="./assets/projetos/baileys/presenteria/banner-presenteria.png" alt="" class="img-fluid">
      </div>
      <div class="col-12 col-lg-6 mb-2 my-lg-5 pb-lg-5">
        <h1 class="brand">Baileys</h1>
        <h1 class="project-title">Gifting de
          Páscoa com
          Baileys</h1>
      </div>
      <div class="col-12 col-lg-6 mb-5 my-lg-5 pb-lg-5">
        <p>Sempre é tempo de presentear com Baileys. a marca criou uma linha de embrulhos
          personalizáveis.</p>

        <p>Baileys é o creme irlandês que promove pausas, momentos deliciosos e combinações atrevidas. Ou seja, os
          motivos perfeitos para oferecê-lo a alguém como presente em diversas ocasiões. Por isso, nesta Páscoa
          assumimos Baileys como uma deliciosa opção de gifting entre os maiores de 18 anos.</p>

        <p>Como a magia de qualquer presente começa pelo seu embrulho, até mesmo para os adultos, é claro que Baileys
          não
          ficaria sem um visual atrevidamente especial. Por esta razão, a Presenteria Baileys foi criada. A ideia foi
          oferecer ao shopper a oportunidade de personalizar sua garrafa de Baileys para presente. Com diversas opções
          de fitas, embrulhos, o público pode transformar sua garrafa ou kit de Baileys em um ousado presente no próprio
          ponto de venda.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 content">
        <div class="element-1 reveal">
          <img class="img-fluid" src="./assets/projetos/baileys/presenteria/image1.png" alt="">
        </div>
        <div class="element-2 reveal">
          <img class="img-fluid" src="./assets/projetos/baileys/presenteria/image2.png" alt="">
        </div>
        <div class="element-3 reveal">
          <img class="img-fluid" src="./assets/projetos/baileys/presenteria/image3.png" alt="">
        </div>
        <div class="element-4 reveal">
          <img class="img-fluid" src="./assets/projetos/baileys/presenteria/image4.png" alt="">
        </div>
        <div class="element-5 reveal d-flex premio">
          <div class="text d-flex flex-column align-items-start justify-content-center">
            <h1 class="title">Prêmio<br>POPAI'22</h1>
            <p class="subtitle">Bronze</p>
            <p>Melhor Display e Peças
              de Merchandising </p>
          </div>
          <img src="./assets/projetos/baileys/presenteria/premio.png" alt="">
        </div>
      </div>
      <div class="img-row flex-wrap d-flex justify-content-center alig">
        <img class="reveal img-fluid" src="./assets/projetos/baileys/presenteria/image5.png" alt="">
        <img class="reveal img-fluid" src="./assets/projetos/baileys/presenteria/image6.png" alt="">
        <img class="reveal img-fluid" src="./assets/projetos/baileys/presenteria/image7.png" alt="">
      </div>
      <div class="col-12 my-5 d-flex alignitems-center justify-content-center mb-5">
        <p class="close pointer" (click)="goBack()"><i class="fa-thin fa-xmark fa-xl me-2"></i>FECHAR</p>
      </div>
    </div>
    <app-back-projects></app-back-projects>
  </div>
</section>
