<section id="project-detail">
  <div id="top"></div>
  <div class="container">
    <div class="row banner">
      <div class="col-12">
        <img src="./assets/BAILEYS_TREATBAR.jpg" alt="" class="img-fluid">
      </div>
      <div class="col-12 col-lg-6 mb-2 my-lg-5 pb-lg-5">
        <h1 class="brand">Baileys</h1>
        <h1 class="project-title">1ª Loja exclusiva de Baileys BR</h1>
      </div>
      <div class="col-12 col-lg-6 mb-5 my-lg-5 pb-lg-5">
        <p class="project-description text-uppercase"><strong>Baileys e Carole Crema lançam a primeira loja Treat Bar em
            São Paulo.</strong></p>
        <p class="project-description"><strong class="text-uppercase">DESAFIO</strong><br>
          Baileys é um creme irlandês que mistura creme de leite fresco e whisky. Seu território de consumo está
          atrelado à indulgência em sobremesas ou complementando outras bebidas. Mas como assumir de verdade esse
          território, quando o produto é vendido apenas em canais do varejo físico ou digital?</p>
        <p class="project-description"><strong class="text-uppercase">IDEIA</strong><br>
          Criamos uma doceria assinada por Baileys e receitas proprietárias. </p>
        <p class="project-description"><strong class="text-uppercase">SOLUÇÃO</strong><br>
          Desenvolvemos a primeira loja exclusiva de Baileys, em parceria com Carole Crema, chef pâtisserie e
          influenciadora da marca. O espaço físico da loja da chef, que leva seu nome, foi repaginado e transformado no
          primeiro Treat Bar de Baileys brasileiro. A loja recebeu uma nova fachada e todo o interior ganhou uma
          decoração proprietária - incluindo uma área instagramável. Além disso, o cardápio contou com sobremesas e
          bebidas exclusivas preparadas pela chef – que recebeu os consumidores durante o período da Páscoa,
          incrementando as vendas, gerando degustação de Baileys e impulsionando novos consumos com o produto.</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 content1">
        <div class="element-1 reveal">
          <img class="img-fluid" src="./assets/projetos/baileys/loja/image1.png" alt="">
        </div>
        <div class="element-2 reveal">
          <img class="img-fluid" src="./assets/projetos/baileys/loja/image2.png" alt="">
        </div>
        <div class="element-3 reveal">
          <img class="img-fluid" src="./assets/projetos/baileys/loja/image3.png" alt="">
        </div>
      </div>
    </div>
  </div>
  <img src="./assets/projetos/baileys/loja/strip.png" class="strip reveal">
  <div class="container">
    <div class="row">
      <div class="col-12 content2">
        <div class="element-1 reveal">
          <img class="img-fluid" src="./assets/projetos/baileys/loja/gallery1.png" alt="">
        </div>
        <div class="element-2 reveal">
          <img class="img-fluid" src="./assets/projetos/baileys/loja/gallery2.png" alt="">
        </div>
        <div class="element-3 reveal">
          <img class="img-fluid" src="./assets/projetos/baileys/loja/gallery4.png" alt="">
        </div>
        <div class="element-4 reveal">
          <img class="img-fluid" src="./assets/projetos/baileys/loja/gallery3.png" alt="">
        </div>
        <div class="element-5 reveal">
          <img class="img-fluid" src="./assets/projetos/baileys/loja/gallery5.png" alt="">
        </div>
        <div class="element-6 reveal">
          <img class="img-fluid" src="./assets/projetos/baileys/loja/gallery6.png" alt="">
        </div>

        <div class="element-7 reveal">
          <img class="img-fluid" src="./assets/projetos/baileys/loja/gallery7.png" alt="">
        </div>
      </div>
      <div class="col-12 my-5 d-flex alignitems-center justify-content-center mb-5">
        <p class="close pointer" (click)="goBack()"><i class="fa-thin fa-xmark fa-xl me-2"></i>FECHAR</p>
      </div>
    </div>
    <app-back-projects></app-back-projects>
  </div>
</section>
