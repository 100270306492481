<section id="project-detail">
  <div class="container">
    <div class="row banner">
      <div class="col-12">
        <div class="video-container">
          <iframe src="https://www.youtube.com/embed/6kbcYGmswRA" frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope;" allowfullscreen></iframe>
        </div>
      </div>
      <div class="col-12 col-lg-6 mb-2 my-lg-5 pb-lg-5">
        <h1 class="brand">Renova BR</h1>
        <h1 class="project-title">A Rampa do Conhecimento</h1>
      </div>
      <div class="col-12 col-lg-6 mb-5 my-lg-5 pb-lg-5">
        <p><strong>DESAFIO: </strong>
          Mostrar, em pleno ano eleitoral, que ainda é possível acreditar na renovação política e que a educação e o
          conhecimento são os melhores caminhos para políticos de todo o Brasil.
        </p>

        <p><strong>IDEIA:</strong>
          Para mostrar que a educação e o conhecimento são os melhores caminhos para a formação de novos políticos,
          desenvolvemos a Rampa do Conhecimento. Uma grande instalação que, de degrau em degrau, mostrou que é possível
          acreditar na renovação da política brasileira. </p>

        <p><strong>SOLUÇÃO:</strong>
          Com vários metros de altura, a Rampa do Conhecimento contou com estrutura feita de livros. Cada um deles com
          temas fundamentais para a formação dos políticos e valores da RenovaBR. Sem contar que o caminho da base até o
          topo contava com uma cadeira de estudante e outra igual às cadeiras do Congresso Nacional.</p>
        <p>
          Outro ponto importante da ação foi a Banca da Renovação. Um espaço inclusivo que distribuiu livros de grandes
          autores e de parceiros da instituição.
          Tudo com um objetivo: mostrar que lugar de político também é na sala de aula.
        </p>
      </div>
    </div>
    <div class="row justify-content-center align-items-center">
      <div class="col-11 col-md-12 p-0 content">
        <div class="element-1 reveal">
          <img class="img-fluid" src="./assets/projetos/renova/image1.png" alt="">
        </div>
        <div class="element-2 reveal">
          <div class="row">
            <div class="col-12 col-md-6 col-lg-4 mb-3">
              <img class="img-fluid" src="./assets/projetos/renova/image2.png" alt="">
            </div>
            <div class="col-12 col-md-6 col-lg-4 mb-3">
              <img class="img-fluid" src="./assets/projetos/renova/image3.png" alt="">
            </div>
            <div class="col-12 col-md-6 col-lg-4 mb-3">
              <img class="img-fluid" src="./assets/projetos/renova/image4.png" alt="">
            </div>
            <div class="col-12 col-md-6 col-lg-4 mb-3">
              <img class="img-fluid" src="./assets/projetos/renova/image5.png" alt="">
            </div>
            <div class="col-12 col-md-6 col-lg-4 mb-3">
              <img class="img-fluid" src="./assets/projetos/renova/image6.png" alt="">
            </div>
            <div class="col-12 col-md-6 col-lg-4 mb-3">
              <img class="img-fluid" src="./assets/projetos/renova/image7.png" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 my-5 d-flex alignitems-center justify-content-center mb-5">
        <p class="close pointer" (click)="goBack()"><i class="fa-thin fa-xmark fa-xl me-2"></i>FECHAR</p>
      </div>
    </div>
    <app-back-projects></app-back-projects>
  </div>
</section>
