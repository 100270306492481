<section id="project-detail">
  <div class="container">
    <div class="row banner">
      <div class="col-12">
        <div class="video-container">
          <iframe src="https://www.youtube.com/embed/Bbu8G8DKuBg" frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope;" allowfullscreen></iframe>
        </div>
      </div>
      <div class="col-12 col-lg-6 mb-2 my-lg-5 pb-lg-5">
        <h1 class="brand">Tanqueray</h1>
        <h1 class="project-title">Lolla’22 #Reencontro Inconfundível</h1>
      </div>
      <div class="col-12 col-lg-6 mb-5 my-lg-5 pb-lg-5">
        <p class="project-description">Após dois anos de pandemia e sem a edição do festival, o reencontro inconfundível
          fez parte desta edição do Lolla BR em 2022. Conceituamos e planejamos toda a estratégia dos conteúdos das
          redes junto aos influenciadores da marca. Luiza Ferraz, Manu Gavassi e Silva fizeram parte deste momento pra
          celebrar o reencontro da marca com o festival, o público e seus amantes.</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 content">
        <div class="element-1 reveal">
          <div class="mockup-video">
            <img class="img-fluid" src="./assets/projetos/tanqueray/lolla/mockup.png" alt="">
            <iframe width="275" height="530" src="https://www.youtube.com/embed/KIaRx8PJWqQ"
              title="Windstorm Window | 1 Hour of Ambience | Vertical Video" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen></iframe>
          </div>
        </div>
        <div class="element-2 reveal">
          <div class="mockup-video">
            <img class="img-fluid" src="./assets/projetos/tanqueray/lolla/mockup.png" alt="">
            <iframe width="275" height="530" src="https://www.youtube.com/embed/QQ3OnBZyoA4"
              title="Windstorm Window | 1 Hour of Ambience | Vertical Video" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen></iframe>
          </div>
        </div>
      </div>
      <div class="col-12 my-5 py-5 d-flex alignitems-center justify-content-center mb-5">
        <p class="close pointer" (click)="goBack()"><i class="fa-thin fa-xmark fa-xl me-2"></i>FECHAR</p>
      </div>
    </div>
    <app-back-projects></app-back-projects>
  </div>
</section>
