<section id="project-detail">
  <div class="container">
    <div class="row banner">
      <div class="col-12">
        <div class="video-container">
          <iframe src="https://www.youtube.com/embed/WbKFosGzCX4" frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope;" allowfullscreen></iframe>
        </div>
      </div>
      <div class="col-12 col-lg-6 mb-2 my-lg-5 pb-lg-5">
        <h1 class="brand">Motorola</h1>
        <h1 class="project-title">Presenteria Dia das Mães</h1>
      </div>
      <div class="col-12 col-lg-6 mb-5 my-lg-5 pb-lg-5">
        <p class="project-description"><strong>Desafio:</strong>
          A tecnologia ainda é um dos presentes mais procurados no Dia das Mães. Mas como transformar um item
          considerado frio, em uma experiência apaixonante e engajadora? </p>
        <p class="project-description">
          <strong>Ideia:</strong>
          Desenvolvemos uma ação que, literalmente, embalou o Dia das Mães com emoção em um espaço para customização de
          presentes.
        </p>
        <p class="project-description"><strong>Solução:</strong>
          Durante o Dia das Mães, levamos estações moveis e interativas para o trade onde o shopper pode criar
          embalagens para seus presentes. Bastava escolher o perfil da sua mãe, escolher caixas, fitas, cartão, mensagem
          e presentear sua mãe com todo o carinho.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 content">
        <div class="element-1 reveal">
          <img class="img-fluid" src="./assets/projetos/motorola/image1.png" alt="">
        </div>
        <div class="element-2 reveal">
          <img class="img-fluid" src="./assets/projetos/motorola/image2.png" alt="">
        </div>
        <div class="element-3 reveal">
          <img class="img-fluid" src="./assets/projetos/motorola/image3.png" alt="">
        </div>
        <div class="element-4 reveal">
          <img class="img-fluid" src="./assets/projetos/motorola/image4.png" alt="">
        </div>
        <div class="element-5 reveal">
          <img class="img-fluid" src="./assets/projetos/motorola/image5.png" alt="">
        </div>
      </div>
      <div class="col-12 my-5 d-flex alignitems-center justify-content-center mb-5">
        <p class="close pointer" (click)="goBack()"><i class="fa-thin fa-xmark fa-xl me-2"></i>FECHAR</p>
      </div>
    </div>
    <app-back-projects></app-back-projects>
  </div>
</section>
