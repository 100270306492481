import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppSharedModule } from './app-shared.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { ProjectsComponent } from './components/projects/projects.component';
import { TanquerayTalksComponent } from './components/project-detalis/tanqueray-talks/tanqueray-talks.component';
import { BaileysPresenteriaComponent } from './components/project-detalis/baileys-presenteria/baileys-presenteria.component';
import { TanquerayHotelComponent } from './components/project-detalis/tanqueray-hotel/tanqueray-hotel.component';
import { GskCasaZosterComponent } from './components/project-detalis/gsk-casa-zoster/gsk-casa-zoster.component';
import { BaileysOvoComponent } from './components/project-detalis/baileys-ovo/baileys-ovo.component';
import { GordonsStewardsComponent } from './components/project-detalis/gordons-stewards/gordons-stewards.component';
import { TanquerayLollaComponent } from './components/project-detalis/tanqueray-lolla/tanqueray-lolla.component';
import { RenovaBrComponent } from './components/project-detalis/renova-br/renova-br.component';
import { MoroloraPresenteriaComponent } from './components/project-detalis/motorola-presenteria/motorola-presenteria.component';
import { GordonsPraiaComponent } from './components/project-detalis/gordons-praia/gordons-praia.component';
import { BaileysLojaComponent } from './components/project-detalis/baileys-loja/baileys-loja.component';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { NgxPageScrollModule } from 'ngx-page-scroll';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProjectsComponent,
    TanquerayTalksComponent,
    BaileysPresenteriaComponent,
    TanquerayHotelComponent,
    GskCasaZosterComponent,
    BaileysOvoComponent,
    GordonsStewardsComponent,
    TanquerayLollaComponent,
    RenovaBrComponent,
    MoroloraPresenteriaComponent,
    GordonsPraiaComponent,
    BaileysLojaComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AppSharedModule,
    BrowserAnimationsModule,
    NgxPageScrollCoreModule,
    NgxPageScrollModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
