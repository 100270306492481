<section id="style-sheet" class="p-5" style="background-color: #EDEEEF;">
  <div class="row justify-content-center py-5">
    <h1>Folha de estilo</h1>
  </div>

  <hr />

  <div class="row justify-content-center py-5">
    <h2>Tipografia</h2>
  </div>

  <div class="row justify-content-center pb-5">
    <div class="col-10 col-lg-2 my-auto">
      <strong>Title</strong>
      <p>Morgen Licht</p>
    </div>
    <div class="col-10 col-lg-8 my-auto">
      <h1 style="font-family: Morgen Licht;">The quickbrown fox jumps over the lazy dog.</h1>
    </div>
  </div>

  <div class="row justify-content-center pb-5">
    <div class="col-10 col-lg-2 my-auto">
      <strong>H1</strong>
      <p>Oswald Bold</p>
    </div>
    <div class="col-10 col-lg-8 my-auto">
      <h1>The quickbrown fox jumps over the lazy dog.</h1>
    </div>
  </div>

  <div class="row justify-content-center pb-5">
    <div class="col-10 col-lg-2 my-auto">
      <strong>H2</strong>
      <p>
        Oswald Bold
      </p>
    </div>
    <div class="col-10 col-lg-8 my-auto">
      <h2>The quickbrown fox jumps over the lazy dog.</h2>
    </div>
  </div>

  <div class="row justify-content-center pb-5">
    <div class="col-10 col-lg-2 my-auto">
      <strong>H3</strong>
      <p>
        Oswald Bold
      </p>
    </div>
    <div class="col-10 col-lg-8 my-auto">
      <h3>The quickbrown fox jumps over the lazy dog.</h3>
    </div>
  </div>

  <div class="row justify-content-center pb-5">
    <div class="col-10 col-lg-2 my-auto">
      <strong>Subtitle</strong>
      <p>
        Roboto
      </p>
    </div>
    <div class="col-10 col-lg-8 my-auto">
      <p class="subtitle">The quickbrown fox jumps over the lazy dog.</p>
    </div>
  </div>

  <div class="row justify-content-center pb-5">
    <div class="col-10 col-lg-2 my-auto">
      <strong>Body</strong>
      <p>
        Roboto
      </p>
    </div>
    <div class="col-10 col-lg-8 my-auto">
      <p>The quickbrown fox jumps over the lazy dog.</p>
    </div>
  </div>

  <div class="row justify-content-center pb-5">
    <div class="col-10 col-lg-2 my-auto">
      <strong>Small</strong>
      <p>
        Roboto
      </p>
    </div>
    <div class="col-10 col-lg-8 my-auto">
      <p class="small">The quickbrown fox jumps over the lazy dog.</p>
    </div>
  </div>

  <div class="row justify-content-center pb-5">
    <div class="col-10 col-lg-2 my-auto">
      <strong>Link</strong>
      <p>
        Roboto
      </p>
    </div>
    <div class="col-10 col-lg-8 my-auto">
      <a href="">The quickbrown fox jumps over the lazy dog.</a>
    </div>
  </div>

  <hr />

  <div class="row justify-content-center py-5">
    <h2>Cores</h2>
  </div>

  <div class="row px-5 pb-5">
    <div class="col-10">
      <h3>Principal</h3>
    </div>
    <div class="col-10 col-sm-6 col-md-4 col-lg-3">
      <div class="row p-3" style="height: 100px;">
        <div class="w-100 h-100" style="background-color: #fecf00;"></div>
      </div>
      <div class="row px-3">
        <p>
          <strong>Amarelo ARCWW</strong><br />
          #fecf00<br />
          Variavel CSS: $color-yellow<br />
        </p>
      </div>
    </div>

    <div class="col-10 col-sm-6 col-md-4 col-lg-3">
      <div class="row p-3" style="height: 100px;">
        <div class="w-100 h-100" style="background-color: #262626;"></div>
      </div>
      <div class="row px-3">
        <p>
          <strong>Preto ARCWW</strong><br />
          #262626<br />
          Variavel CSS: $color-border-pro<br />
        </p>
      </div>
    </div>
  </div>


  <div class="row px-5 pb-5">
    <div class="col-10">
      <h3>Secundário</h3>
    </div>
    <div class="col-10 col-sm-6 col-md-4 col-lg-3">
      <div class="row p-3" style="height: 100px;">
        <div class="w-100 h-100" style="background-color:#FFFFFF"></div>
      </div>
      <div class="row px-3">
        <p>
          <strong>Branco</strong><br />
          #FFFFFF<br />
          Variavel CSS: $color-white<br />
        </p>
      </div>
    </div>
    <div class="col-10 col-sm-6 col-md-4 col-lg-3">
      <div class="row p-3" style="height: 100px;">
        <div class="w-100 h-100" style="background-color:#333132"></div>
      </div>
      <div class="row px-3">
        <p>
          <strong>Cinza</strong><br />
          #333132<br />
          Variavel CSS: $color-gray<br />
        </p>
      </div>
    </div>
    <div class="col-10 col-sm-6 col-md-4 col-lg-3">
      <div class="row p-3" style="height: 100px;">
        <div class="w-100 h-100" style="background-color:#939598"></div>
      </div>
      <div class="row px-3">
        <p>
          <strong>Cinza claro</strong><br />
          #939598<br />
          Variavel CSS: $color-light-gray<br />
        </p>
      </div>
    </div>
  </div>
  <hr />
</section>
