<section id="project-detail">
  <div class="container">
    <div class="row banner">
      <div class="col-12">
        <div class="video-container">
          <iframe src="https://www.youtube.com/embed/o6rxzz8yDCU" frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope;" allowfullscreen></iframe>
        </div>
      </div>
      <div class="col-12 col-lg-6 mb-2 my-lg-5 pb-lg-5">
        <h1 class="brand">Tanqueray</h1>
        <h1 class="project-title">Inconfundível <br>Talks</h1>
      </div>
      <div class="col-12 col-lg-6 mb-5 my-lg-5 pb-lg-5">
        <p class="project-description">Inconfundível Talks é a primeira web série de Tanqueray lançada no Brasil. O
          conceito global de Tanqueray é o “Gin Inconfundível”. Como Tanqueray é uma marca premium, foi muito importante
          materializar o que faz a experiência com o gin ser inconfundível. Por isso, na série, o bartender embaixador
          de Tanqueray, Kennedy Nascimento, visita os melhores bares de São Paulo para conversar com seus bartenders e
          descobrir qual segredo de cada um para oferecer uma experiência de drinks inconfundível. Na busca pelas
          respostas, Kennedy descobre explicações que vão muito além do bar e conta tudo isso nos episódios.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 content">
        <div class="element-1 reveal">
          <img class="img-fluid" src="./assets/projetos/tanqueray/talks/image1.png" alt="">
        </div>
        <div class="element-2 reveal">
          <img class="img-fluid" src="./assets/projetos/tanqueray/talks/image2.png" alt="">
        </div>
        <div class="element-3 reveal">
          <img class="img-fluid" src="./assets/projetos/tanqueray/talks/image3.png" alt="">
        </div>
        <div class="element-4 reveal">
          <div class="video-container">
            <iframe src="https://www.youtube.com/embed/VgnR41EGoZE" frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope;" allowfullscreen></iframe>
          </div>
        </div>
        <div class="element-5 reveal">
          <img class="img-fluid" src="./assets/projetos/tanqueray/talks/image5.png" alt="">
        </div>
        <div class="element-6 reveal">
          <img class="img-fluid" src="./assets/projetos/tanqueray/talks/image6.png" alt="">
        </div>
        <div class="element-7 reveal">
          <div class="video-container">
            <iframe src="https://www.youtube.com/embed/p4YwrDNGRW8" frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope;" allowfullscreen></iframe>
          </div>
        </div>
      </div>
      <div class="col-12 d-flex alignitems-center justify-content-center mb-5 reveal">
        <img class="img-fluid w-75" src="./assets/projetos/tanqueray/talks/image8.png" alt="">
      </div>
      <div class="col-12 my-5 d-flex alignitems-center justify-content-center mb-5">
        <p class="close pointer" (click)="goBack()"><i class="fa-thin fa-xmark fa-xl me-2"></i>FECHAR</p>
      </div>
    </div>
    <app-back-projects></app-back-projects>
  </div>
</section>
