<section id="project-detail">
  <div class="container">
    <div class="row banner">
      <div class="col-12">
        <img src="./assets/projetos/gordons/stewards/banner.png" alt="" class="img-fluid">
      </div>
      <div class="col-12 col-lg-6 mb-2 my-lg-5 pb-lg-5">
        <h1 class="brand">Gordon’s</h1>
        <h1 class="project-title">De Costas para a Paixão</h1>
      </div>
      <div class="col-12 col-lg-6 mb-5 my-lg-5 pb-lg-5">
        <p class="project-description"><strong>INSIGHT:</strong>
          Steward são seguranças que ficam monitorando cada movimento da torcida durante os jogos de futebol. Por isso,
          apesar de estarem a poucos metros do campo, não conseguem ver sequer um lance das partidas, porque estão de
          costas, vigiando os torcedores.
        </p>

        <p class="project-description"><strong>IDEIA:</strong>
          Para compensar essa injustiça, Gordon’s transformou a rotina de quem não pode assistir ao time do coração.
          Selecionamos os dois stewards fanáticos pelo Ceará que mais tempo passaram de costas para o campo durante os
          jogos do Vozão. Sem saber, os seguranças foram levados a um camarote exclusivo de Gordon’s durante um jogo da
          Copa do Nordeste. Pela primeira vez, eles puderam assistir à sua paixão como nunca haviam feito antes. Mas o
          posto de trabalho não ficou vazio: uma estátua de gelo em tamanho real dos stewards, devidamente refrescante,
          foi instalada no local.
        </p>

      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="video-container reveal">
          <iframe src="https://www.youtube.com/embed/spE-hxmShuI" frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope;" allowfullscreen></iframe>
        </div>
      </div>
      <div class="col-12 my-5 py-5 d-flex alignitems-center justify-content-center mb-5">
        <p class="close pointer" (click)="goBack()"><i class="fa-thin fa-xmark fa-xl me-2"></i>FECHAR</p>
      </div>
    </div>
    <app-back-projects></app-back-projects>
  </div>
</section>
