<section id="project-detail">
  <div class="container">
    <div class="row banner">
      <div class="col-12">
        <div class="video-container">
          <iframe src="https://www.youtube.com/embed/lkWe9RraTTk" frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope;" allowfullscreen></iframe>
        </div>
      </div>
      <div class="col-12 col-lg-6 mb-2 my-lg-5 pb-lg-5">
        <h1 class="brand">Tanqueray</h1>
        <h1 class="project-title">Hotel Inconfundível Tanqueray</h1>
      </div>
      <div class="col-12 col-lg-6 mb-5 my-lg-5 pb-lg-5">
        <p class="project-description">O projeto Hotel Inconfundível Tanqueray ambientou seis quartos do hotel Yoo2 para
          uma imersão no mundo de
          Tanqueray. Na ocasião, o cantor Xamã será anunciado como novo embaixador de Tanqueray, ampliando o squad de
          influenciadores, que já conta com Manu Gavassi e Luiza Ferraz.</p>
        <p class="project-description">
          Durante o período do festival, em parceria com o hotel, que tem uma linda vista para a Praia de Botafogo, no
          Rio de Janeiro, a marca promoveu uma experiência imersiva da marca pensada nas jornadas dos festivais, neste
          caso o Rock in Rio. Sabemos que toda a expectativa do público se baseia desde a compra de ingressos, a
          hospedagem, aos looks, esquentas e, claro, as companhias. E foi com esse olhar que criamos esta ação, em que
          trazemos o ponto mais importante desta jornada pra que a vivência do festival seja completa: as estadias. Com
          base em cada produto de Tanqueray, resgatamos a essência e a etnia dos ingredientes para que o público viva
          uma experiência inconfundível. Do design da garrafa à origem dos botânicos, cada detalhe foi criado para
          decorar e compor os quartos da marca de um jeito inesquecível.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 content">
        <div class="element-1 reveal">
          <img class="img-fluid" src="./assets/projetos/tanqueray/hotel/image1.png" alt="">
        </div>
        <div class="element-2 reveal">
          <img class="img-fluid" src="./assets/projetos/tanqueray/hotel/image2.png" alt="">
        </div>
        <div class="element-3 reveal">
          <img class="img-fluid" src="./assets/projetos/tanqueray/hotel/image3.png" alt="">
        </div>
        <div class="element-4 reveal">
          <img class="img-fluid" src="./assets/projetos/tanqueray/hotel/image4.png" alt="">
        </div>
        <div class="element-5 reveal">
          <img class="img-fluid" src="./assets/projetos/tanqueray/hotel/image5.png" alt="">
        </div>
        <div class="element-6 reveal">
          <img class="img-fluid" src="./assets/projetos/tanqueray/hotel/image6.png" alt="">
        </div>
        <div class="element-7 reveal">
          <img class="img-fluid" src="./assets/projetos/tanqueray/hotel/image7.png" alt="">
        </div>
        <div class="element-8 reveal">
          <img class="img-fluid" src="./assets/projetos/tanqueray/hotel/image8.png" alt="">
        </div>
        <div class="element-9 reveal">
          <img class="img-fluid" src="./assets/projetos/tanqueray/hotel/image9.png" alt="">
        </div>
        <div class="element-10 reveal">
          <img class="img-fluid" src="./assets/projetos/tanqueray/hotel/image10.png" alt="">
        </div>
        <div class="element-11 reveal">
          <img class="img-fluid" src="./assets/projetos/tanqueray/hotel/image11.png" alt="">
        </div>
      </div>
      <!-- <div class="col-12 d-flex alignitems-center justify-content-center mb-5 reveal">
        <img class="img-fluid w-75" src="./assets/projetos/tanqueray/hotel/image8.png" alt="">
      </div> -->
      <div class="col-12 my-5 d-flex alignitems-center justify-content-center mb-5">
        <p class="close pointer" (click)="goBack()"><i class="fa-thin fa-xmark fa-xl me-2"></i>FECHAR</p>
      </div>
    </div>
    <app-back-projects></app-back-projects>
  </div>
</section>
