import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-renova-br',
  templateUrl: './renova-br.component.html',
  styleUrls: ['./renova-br.component.scss'],
})
export class RenovaBrComponent implements OnInit {
  rootNode: any;

  constructor(private elementRef: ElementRef, private location: Location) {}

  ngOnInit(): void {
    this.rootNode = this.elementRef.nativeElement;
    this.scrollTop();
  }

  //Função para revelar elementos na tela
  reveal() {
    var reveals = this.rootNode.querySelectorAll('.reveal');
    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      //elementVisible define em qual height vai revelar o elemento
      var elementVisible = 50;
      if (elementTop < windowHeight! - elementVisible!) {
        reveals[i].classList.add('active');
      } else {
        reveals[i].classList.remove('active');
      }
    }
  }

  //Event Listener do scroll da tela
  @HostListener('window:scroll', []) onWindowScroll() {
    this.reveal();
  }

  goBack() {
    this.location.back();
  }

  scrollTop() {
    window.scrollTo(0, 0);
  }
}
