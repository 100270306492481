<nav class="navbar navbar-expand-lg fixed-top">
  <div class="container px-2 px-lg-0">
    <a class="navbar-brand w-25 d-flex justify-content-start" href="#"><img src="./assets/Logo_ARC.png" alt=""></a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup"
      aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
      <div class="navbar-nav w-100 d-flex justify-content-end align-items-center">
        <a class="nav-link ms-4 active" aria-current="page" href="#quem-somos">QUEM SOMOS</a>
        <a class="nav-link ms-4" href="#filosofia">NOSSA FILOSOFIA</a>
        <a class="nav-link ms-4" href="#projetos">PROJETOS</a>
        <a class="nav-link ms-4" href="#oque-fazemos">O QUE FAZEMOS</a>
        <a class="nav-link ms-4" href="#contato">CONTATO</a>
      </div>
    </div>
  </div>
</nav>
