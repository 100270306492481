<footer>
  <div class="carousel">
    <div class="container">
      <!-- <ngx-slick-carousel class="carousel" [config]="{ slidesToShow: 4, slidesToScroll: 1, infinite: true }">
        <div *ngFor="let logo of logos">
          <img src="{{ logo.image }}" alt="{{ logo.name }}" />
        </div>
      </ngx-slick-carousel> -->
      <ngx-slick-carousel class="carousel d-flex align-items-center" #slickModal="slick-carousel"
        [config]="slickConfig">
        <div ngxSlickItem *ngFor="let logo of logos" class="slide d-flex align-items-center">
          <img src="{{ logo.image }}" alt="">
        </div>
      </ngx-slick-carousel>
    </div>
  </div>
  <div class="container">
    <hr class="my-5">
    <div class="d-flex flex-column flex-lg-row justify-content-between align-items-baseline">
      <div class="d-flex flex-column align-items-start justify-content-between">
        <img src="./assets/logo-footer.png" alt="">
        <p class="slogan my-4">E ai,<br>
          vamos construir<br>
          jornadas apaixonantes? </p>
      </div>
      <div class="d-flex flex-column align-items-center align-items-lg-end justify-content-between">
        <p>São Paulo BR</p>
        <div class="d-flex justify-content-between align-items-center w-100 my-4">
          <a class="social-icon" href="https://www.youtube.com/@arcworldwidebrasil2895" target="_blank"><i
              class="fa-brands fa-2xl fa-youtube mx-3 mx-lg-1"></i></a>
          <a class="social-icon" href="https://www.instagram.com/arcww_br/" target="_blank"><i
              class="fa-brands fa-2xl fa-instagram mx-3 mx-lg-1"></i></a>
          <a class="social-icon" href="https://www.facebook.com/arcworldwidebrasil" target="_blank"><i
              class="fa-brands fa-2xl fa-square-facebook mx-3 mx-lg-1"></i></a>
          <a class="social-icon" href="https://br.linkedin.com/company/arcworldwidebrasil" target="_blank"><i
              class="fa-brands fa-2xl fa-linkedin mx-3 mx-lg-1"></i></a>
        </div>
        <a href="mailto:contato@arcww.com.br">Fale com a gente!</a>
      </div>
    </div>
    <div class="d-flex flex-column flex-lg-row justify-content-between align-items-center pb-3">
      <div class="d-flex flex-column align-items-start justify-content-between mb-3 mb-lg-0">
        <img src="./assets/publicis-groupe.svg" style="height: 50px;" alt="" class="img-fluid">
      </div>
      <div class="d-flex flex-column align-items-center justify-content-between">
        <p class="copy align-self-end m-0">
          ©2023 arc worldwide brasil. TODOS OS DIREITOS RESERVADOS
        </p>
      </div>
    </div>
  </div>
</footer>
