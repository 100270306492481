import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { BaileysLojaComponent } from './components/project-detalis/baileys-loja/baileys-loja.component';
import { BaileysOvoComponent } from './components/project-detalis/baileys-ovo/baileys-ovo.component';
import { BaileysPresenteriaComponent } from './components/project-detalis/baileys-presenteria/baileys-presenteria.component';
import { GordonsPraiaComponent } from './components/project-detalis/gordons-praia/gordons-praia.component';
import { GordonsStewardsComponent } from './components/project-detalis/gordons-stewards/gordons-stewards.component';
import { GskCasaZosterComponent } from './components/project-detalis/gsk-casa-zoster/gsk-casa-zoster.component';
import { MoroloraPresenteriaComponent } from './components/project-detalis/motorola-presenteria/motorola-presenteria.component';
import { RenovaBrComponent } from './components/project-detalis/renova-br/renova-br.component';
import { TanquerayHotelComponent } from './components/project-detalis/tanqueray-hotel/tanqueray-hotel.component';
import { TanquerayLollaComponent } from './components/project-detalis/tanqueray-lolla/tanqueray-lolla.component';
import { TanquerayTalksComponent } from './components/project-detalis/tanqueray-talks/tanqueray-talks.component';
import { ProjectsComponent } from './components/projects/projects.component';
import { StylesheetComponent } from './shared/stylesheet/stylesheet.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'projetos', component: ProjectsComponent },
  { path: 'inconfundivel-talks', component: TanquerayTalksComponent },
  { path: 'inconfundivel-hotel', component: TanquerayHotelComponent },
  { path: 'baileys-presenteria', component: BaileysPresenteriaComponent },
  { path: 'casa-zoster', component: GskCasaZosterComponent },
  { path: 'baileys-ovo', component: BaileysOvoComponent },
  { path: 'gordons-stewards', component: GordonsStewardsComponent },
  { path: 'tanqueray-lolla', component: TanquerayLollaComponent },
  { path: 'renova-br', component: RenovaBrComponent },
  { path: 'motorola-presenteria', component: MoroloraPresenteriaComponent },
  { path: 'gordons-praia', component: GordonsPraiaComponent },
  { path: 'baileys-loja', component: BaileysLojaComponent },
  { path: 'stylesheet', component: StylesheetComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
