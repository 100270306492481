import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  logos = [
    { name: 'Logo 1', image: 'assets/logo-baileys.png' },
    { name: 'Logo 2', image: 'assets/logo-gorgons.png' },
    { name: 'Logo 1', image: 'assets/GM.png' },
    { name: 'Logo 3', image: 'assets/logo-motorola.png' },
    { name: 'Logo 4', image: 'assets/logo-tanqueray.png' },
    { name: 'Logo 5', image: 'assets/logo-vivo.png' },
    { name: 'Logo 2', image: 'assets/PM_Brasil.png' },
    { name: 'Logo 3', image: 'assets/gsk_grayscale.svg' },
    { name: 'Logo 4', image: 'assets/logo-liane.png' },
    { name: 'Logo 5', image: 'assets/logo-nestle.png' },
    { name: 'Logo 6', image: 'assets/renova_cinza.svg' },
    { name: 'Logo 1', image: 'assets/logo-baileys.png' },
    { name: 'Logo 2', image: 'assets/logo-gorgons.png' },
    { name: 'Logo 1', image: 'assets/GM.png' },
    { name: 'Logo 3', image: 'assets/logo-motorola.png' },
    { name: 'Logo 4', image: 'assets/logo-tanqueray.png' },
    { name: 'Logo 5', image: 'assets/logo-vivo.png' },
    { name: 'Logo 2', image: 'assets/PM_Brasil.png' },
    { name: 'Logo 3', image: 'assets/gsk_grayscale.svg' },
    { name: 'Logo 4', image: 'assets/logo-liane.png' },
    { name: 'Logo 5', image: 'assets/logo-nestle.png' },
    { name: 'Logo 6', image: 'assets/renova_cinza.svg' },
  ];

  slickConfig = {
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: true,
    arrows: true,
    autoplay: true,
    prevArrow:
      '<div class="p-2"><i class="fa-thin fa-chevron-left fa-xl"></i></div>',
    nextArrow:
      '<div class="p-2"><i class="fa-thin fa-chevron-right fa-xl"></i></div>',
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  constructor() {}

  ngOnInit(): void {}
}
