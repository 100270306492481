import {
  Component,
  HostBinding,
  OnInit,
  ElementRef,
  HostListener,
} from '@angular/core';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
})
export class ProjectsComponent implements OnInit {
  rootNode: any;

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.rootNode = this.elementRef.nativeElement;
  }

  //Função para revelar elementos na tela
  reveal() {
    var reveals = this.rootNode.querySelectorAll('.reveal');
    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 50;
      if (elementTop < windowHeight! - elementVisible!) {
        reveals[i].classList.add('active');
      } else {
        reveals[i].classList.remove('active');
      }
    }
  }

  //Event Listener do scroll da tela
  @HostListener('window:scroll', []) onWindowScroll() {
    this.reveal();
  }
}
