import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-tanqueray-hotel',
  templateUrl: './tanqueray-hotel.component.html',
  styleUrls: ['./tanqueray-hotel.component.scss'],
})
export class TanquerayHotelComponent implements OnInit {
  rootNode: any;

  constructor(private elementRef: ElementRef, private location: Location) {}

  ngOnInit(): void {
    this.rootNode = this.elementRef.nativeElement;
    this.scrollTop();
  }

  //Função para revelar elementos na tela
  reveal() {
    var reveals = this.rootNode.querySelectorAll('.reveal');
    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      //elementVisible define em qual height vai revelar o elemento
      var elementVisible = 50;
      if (elementTop < windowHeight! - elementVisible!) {
        reveals[i].classList.add('active');
      } else {
        reveals[i].classList.remove('active');
      }
    }
  }

  //Event Listener do scroll da tela
  @HostListener('window:scroll', []) onWindowScroll() {
    this.reveal();
    const scrollPosition =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    if (scrollPosition > 450) {
      document.getElementById('stampBoard')!.classList.add('active');
    } else {
      document.getElementById('stampBoard')!.classList.remove('active');
    }
  }

  goBack() {
    this.location.back();
  }

  scrollTop() {
    window.scrollTo(0, 0);
  }
}
