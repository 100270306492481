<section id="project-detail">
  <!-- <div class="video-container">
    <iframe src="https://www.youtube.com/embed/6aFxA699pRA" frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope;" allowfullscreen></iframe>
  </div> -->
  <div class="container">
    <div class="row banner">
      <div class="col-12">
        <div class="video-container">
          <iframe src="https://www.youtube.com/embed/R9i11oCnAVQ" frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope;" allowfullscreen></iframe>
        </div>
      </div>
      <div class="col-12 col-lg-6 mb-2 my-lg-5 pb-lg-5">
        <h1 class="brand">GSK</h1>
        <h1 class="project-title">Casa Zoster</h1>
      </div>
      <div class="col-12 col-lg-6 mb-5 my-lg-5 pb-lg-5">
        <p class="project-description"><strong>Desafio</strong><br>
          Como criar uma ativação para a vacina da Herpes Zoster, uma doença que causa dores muito fortes e intensas
          para seus pacientes, mostrando seus efeitos no corpo das pessoas de forma direta e impactante, mas sem
          machucar o público.</p>
        <p class="project-description">
          <strong>Insight</strong><br>
          Muitos pacientes com Herpes Zoster associam suas dores a itens do cotidiano. Eletricidade, calor, estacas e
          outros objetos que podemos retratar com móveis e utensílios do dia a dia. Afinal, uma mera atividade cotidiana
          pode se tornar insuportável por conta da doença.
        </p>
        <p class="project-description">
          <strong>Ideia</strong><br>
          Para mostrar como as dores da doença são realmente brutais, criamos a Casa Zoster. Uma ativação que usou
          relato de pacientes reais da doença para criar cenários sinestésicos e visualmente brutais para o público.<br>
          A ideia levou 3 ambientes diferentes para os maiores shoppings do país. Em cada um deles, as pessoas podiam
          sentir, de forma visual, como a doença pode ser trazer dores muito intensas e ainda impedir de fazer as
          tarefas mais simples do dia a dia.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 content">
        <div class="element-1 reveal">
          <img class="img-fluid" src="./assets/projetos/gsk/image1.png" alt="">
        </div>
        <div class="element-2 reveal">
          <img class="img-fluid" src="./assets/projetos/gsk/image2.png" alt="">
        </div>
        <div class="element-3 reveal">
          <img class="img-fluid" src="./assets/projetos/gsk/image3.png" alt="">
        </div>
        <div class="element-4 reveal">
          <img class="img-fluid" src="./assets/projetos/gsk/image4.png" alt="">
        </div>
        <div class="element-5 reveal">
          <img class="img-fluid" src="./assets/projetos/gsk/image5.png" alt="">
        </div>
        <div class="element-6 reveal">
          <img class="img-fluid" src="./assets/projetos/gsk/image6.png" alt="">
        </div>
        <div class="element-7 reveal">
          <img class="img-fluid" src="./assets/projetos/gsk/image7.png" alt="">
        </div>
        <div class="element-8 reveal">
          <img class="img-fluid" src="./assets/projetos/gsk/image8.png" alt="">
        </div>
        <div class="element-9 reveal">
          <img class="img-fluid" src="./assets/projetos/gsk/image9.png" alt="">
        </div>
      </div>
      <div class="col-12 my-5 d-flex alignitems-center justify-content-center mb-5">
        <p class="close pointer" (click)="goBack()"><i class="fa-thin fa-xmark fa-xl me-2"></i>FECHAR</p>
      </div>
    </div>
    <app-back-projects></app-back-projects>
  </div>
</section>
